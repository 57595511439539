<!-- @format -->

<template>
	<Transition name="slide-fade">
		<div>
			<div class="modal-backdrop">
				<div
					class="modal"
					role="dialog"
					aria-labelledby="modalTitle"
					aria-describedby="modalDescription"
				>
					<header class="modal-header" id="modalTitle">
						<slot name="header">{{ $props.header }}</slot>
					</header>
					<div>
						<b-button
							style="
								background-color: rgb(182, 90, 92);
								color: white;
								width: 280px;
							"
							@click="$props.closePopup"
							aria-label="Close modal"
						>
							X Close
						</b-button>
					</div>
					<section class="modal-body" id="modalDescription">
						<slot name="body"></slot>
					</section>
					<footer class="modal-footer">
						<slot name="footer">{{ $props.footer }}</slot>
					</footer>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script>
export default {
	name: 'V3Popup',
	props: {
		closePopup: {
			type: Function,
		},
		header: {
			type: String,
			default: '',
		},
		footer: {
			type: String,
			default: '',
		},
	},
}
</script>

<style scoped>
.modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.9);
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100000000001;
}
.modal {
	background: rgb(41, 44, 52);
	box-shadow: 2px 2px 20px 1px;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	max-width: 1100px;
	max-height: 90%;
	height: 100%;
	margin: auto;
	padding: 20px;
	opacity: 100%;
	overflow-y: auto;
}
.modal-header,
.modal-footer {
	padding: 15px;
	display: flex;
	color: white;
	font-size: larger;
	font-weight: 700;
}
.modal-header {
	position: relative;
	justify-content: space-between;
}
.modal-footer {
	flex-direction: column;
	justify-content: flex-end;
	margin-bottom: 30px;
}
.modal-body {
	position: relative;
	padding: 20px 10px;
	min-height: 75%;
}
.slide-fade-enter-active {
	transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateX(20px);
	opacity: 0;
}
</style>
